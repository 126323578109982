/*
    Colors used: Orange (#F9B872, #FAE7A5), powder blue (#B6E1E7)
        https://www.ashortjourney.com/
    
    Deep red (#8E0D3C), blackcurrant #1D1842), orange (#EF3B33), rose pink (#FDA1A2)
        https://insidethehead.co/
    
    
*/

//color scheme 1 - simple but bold
export const freshYellow = '#f7ef6a';

export const vermillionGreen = '#93c178';
export const vermillionGreenDark = '#7AA85F';
export const sunshineBlue = '#465c8b';
export const cleanWhite = '#dfdce3';

//color scheme 2 - earthy and fresh
export const terracotta = '#945d60';
export const herb = '#626e60';
export const chilli = '#af473c';
export const charcoal = '#3c3c3c';


//color scheme 3 - fresh blues
export const feather = '#77c9d4';
export const marine = '#57bc90';
export const forest = '#015249';
export const sleekGrey = '#a5a5af';


//color scheme 4 - blues and yellow
export const turquoise = '#026670';
export const blueGreen = '#9fedd7';
export const lightYellow = '#fef9c7';
export const yellow = '#fce181';
export const grey = '#edeae5';
