import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { FaLaptopCode } from 'react-icons/fa';
import {resumeData} from './resume';
import {freshYellow, vermillionGreen, vermillionGreenDark, sunshineBlue, cleanWhite, terracotta, herb, chilli, charcoal, feather, marine, forest, sleekGrey, turquoise, blueGreen, lightYellow, yellow, grey} from './colors';

const JobSkills = ({skills}) => {
  return skills.map(skill => {
    return (
      <p>
        {skill}
      </p>
    )
  })
}

const getColor1 = (index) => {
  let color = freshYellow;
  console.log(index);
  console.log('mod: ' + index % 3);

  switch (index % 3) {
    case 0:
      color = sunshineBlue;
      break;
    case 1:
      color = vermillionGreen;
      break;
    case 2:
      color = freshYellow;
      break;
    default:
      console.log(`what color should this be?`);
  }
  return color 
}

// const getBorder1 = (index) => {
//   let color = freshYellow;
//   console.log(index);
//   console.log('mod: ' + index % 3);

//   switch (index % 3) {
//     case 0:
//       color = '#00103F';
//       break;
//     case 1:
//       color = '#47752C';
//       break;
//     case 2:
//       color = '#ABA31E';
//       break;
//     default:
//       console.log(`what color should this be?`);
//   }
//   return color 
// }

const getColor2 = (index) => {
  let color = terracotta;
  console.log(index);
  console.log('mod: ' + index % 3);

  switch (index % 3) {
    case 0:
      color = terracotta;
      break;
    case 1:
      color = herb;
      break;
    case 2:
      color = chilli;
      break;
    default:
      console.log(`what color should this be?`);
  }
  return color 
}


const getColor3 = (index) => {
  let color = forest;
  console.log(index);
  console.log('mod: ' + index % 3);

  switch (index % 3) {
    case 0:
      color = feather;
      break;
    case 1:
      color = marine;
      break;
    case 2:
      color = forest;
      break;
    default:
      console.log(`what color should this be?`);
  }
  return color 
}

const isDateOnRightSide = (index) => {
  return index % 2 === 0
};


const JobItem = () => {
  return resumeData.map((job, index) => {
    const brandColor = getColor3(index);
    const classes = isDateOnRightSide(index) ? 'vertical-timeline-element--work date-on-right' : 'vertical-timeline-element--work date-on-left' ;
    return (
      <VerticalTimelineElement
          className={classes}
          contentStyle={{ background: '#fff', color: charcoal, borderStyle: 'solid', borderBottomWidth: '1px', borderColor: brandColor, boxShadow: `10px 5px 2px 1px rgba(165, 165, 175, .2)`}}
          contentArrowStyle={{ borderRight: '7px solid', color: brandColor }}
          date={job.dateRange}
          dateClassName="jobDateRange"
          iconStyle={{ background: brandColor, color: '#fff' }}
          icon={<FaLaptopCode />}
          key={job.title} 
        >
          <h3 className="vertical-timeline-element-title">{job.title}</h3>
          <h4 className="vertical-timeline-element-subtitle">{job.company} - {job.location}</h4>
          <JobSkills skills={job.jobSkills}/>
        </VerticalTimelineElement>
    )
  })
}

function Timeline() {
  return (
    <VerticalTimeline lineColor={sleekGrey}>
      <JobItem/>
    </VerticalTimeline>
    
  );
  }
  
  export default Timeline;
  