export const resumeData = [
  {
    company: 'Principal Financial Group',
    location: 'Des Moines, IA', 
    dateRange: 'September 2021 – Present',
    title: 'Senior Software Engineer II',
    jobSkills: [
      'Technical Lead of an Engineering team of 9',
      'Worked with business partners to convert business requirements into a technical solution',
      'Architect solutions for major financial advice projects',
      'Lead vendor integrations with Money Guide Pro, Rightbridge, ScheduleOnce, Box, Acrobat Adobe Sign, Morningstar'
    ]
  },
  {
    company: 'RobustWealth',
    location: 'Des Moines, IA', 
    dateRange: 'February 2019 – September 2021',
    title: 'Senior Engineer',
    jobSkills: [
        'Supported the client facing facing platform',
        'Skills: Vue.js, AWS Services (Lambda, RDS, Cognito SQS, SNS, EventBridge), Node.js'
    ]
  },
  {
    company: 'Principal Financial Group',
    location: 'Des Moines, IA', 
    dateRange: 'April 2016 – February 2019',
    title: 'Software Developer',
    jobSkills: [
      'Worked collaboratively in a SAFe SCRUM team',
      'Developed and improved applications with JavaScript, SQL, Drupal, CSS, jQuery, Java',
      'Implemented vendor products like Salesforce Marketing Cloud',
      'Supported the corporate marketing department to enhance user experience for our customers and smarter marketing solutions'
    ]
  },
  {
    company: 'Principal Financial Group',
    location: 'Des Moines, IA', 
    dateRange: 'September 2015 – April 2016',
    title: 'Quality Control Analyst',
    jobSkills: [
      'Worked collaboratively in a SCRUM Agile team',
      'Lead the QA effort for the digital style guide during Principal’s rebranding',
      'Wrote and executed test plans',
      'Used automation testing tools to test more efficiently'
    ]
  },
  {
    company: 'Principal Financial Group',
    location: 'Des Moines, IA', 
    dateRange: 'August 2011 – September 2015',
    title: 'Automation Engineer',
    jobSkills: [
      'Lead, managed and resourced a portfolio',
      'Worked collaboratively with colleagues',
      'Used automation testing tools to support cross-departments automation needs',
      'Trained new employees',
      'Created and enforced an automation testing strategy'
    ]
  },
]