import logo from './logo.svg';
import './App.css';
import 'react-vertical-timeline-component/style.min.css';
import Timeline from './Timeline';


function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/Logo.png" height="50%" width="50%"/>
      </header>
      <body className="Body">
        <Timeline/>
      </body>
    </div>
  );
}

export default App;
